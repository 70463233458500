import { useNode } from '@craftjs/core';

import { ListingSectionSettings } from './ListingSectionSettings';
import NewListingsSection from 'containers/CustomLandingPage/components/NewListingsSection';
import { useGetListings } from 'hooks/api/listings/useGetListings';
import { useGetConfiguration } from 'hooks/api/useGetConfiguration';
import { parse } from 'util/urlHelpers';

type Props = {
  collectionId?: number;
  title?: string;
  subtitle?: string;
  className?: string;
  buttonText?: string;
};

function ListingSectionWrapper({ collectionId, title, subtitle, className, buttonText }: Props) {
  const {
    connectors: { connect, drag },
  } = useNode();

  const { data: configData } = useGetConfiguration();
  const collection = configData?.collections?.find(c => c.id === collectionId);

  const { data: listingsData } = useGetListings(parse(collection?.filterLink));

  const listings = listingsData?.pages.flatMap(page => page.listings) || [];

  return (
    <NewListingsSection
      listings={listings.slice(0, 24)}
      className={className}
      title={title}
      subtitle={subtitle}
      filterLink={collection?.filterLink}
      buttonText={buttonText}
      ref={ref => ref && connect(drag(ref))}
    />
  );
}

ListingSectionWrapper.craft = {
  displayName: 'Listings',
  props: {
    collectionId: undefined,
    title: 'Recently added',
    subtitle: 'Pre-loved',
    className: '',
    buttonText: '',
  },
  related: {
    settings: ListingSectionSettings,
  },
};

export default ListingSectionWrapper;
