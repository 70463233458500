import { useNode } from '@craftjs/core';

import FieldCheckbox from 'components/FieldCheckboxV2/FieldCheckbox';
import { useGetConfiguration } from 'hooks/api/useGetConfiguration';

export const CarouselSectionSettings = () => {
  const {
    actions: { setProp },
    title,
    subtitle,
    collectionId,
    items,
    buttonText,
    buttonLinkType,
    buttonCustomPath,
  } = useNode(node => ({
    title: node.data.props.title,
    subtitle: node.data.props.subtitle,
    collectionId: node.data.props.collectionId,
    items: node.data.props.items,
    buttonText: node.data.props.buttonText,
    buttonLinkType: node.data.props.buttonLinkType,
    buttonCustomPath: node.data.props.buttonCustomPath,
  }));

  const { data: configData } = useGetConfiguration();
  const collections = configData?.collections || [];

  return (
    <div className="flex flex-col gap-2">
      <div>Carousel Section Settings</div>

      <label>
        Title
        <textarea
          value={title}
          onChange={e => setProp((props: any) => (props.title = e.target.value))}
          className="w-full p-1"
          rows={1}
        />
      </label>

      <label>
        Subtitle
        <textarea
          value={subtitle}
          onChange={e => setProp((props: any) => (props.subtitle = e.target.value))}
          className="w-full p-1"
          rows={1}
        />
      </label>

      <div className="flex flex-col gap-2 border-t border-gray-200 pt-4 mt-4">
        <h3 className="font-bold">Button Settings</h3>

        <label>
          Button Text (optional)
          <textarea
            value={buttonText || ''}
            onChange={e => setProp((props: any) => (props.buttonText = e.target.value))}
            className="w-full p-1"
            rows={1}
            placeholder="Leave empty to hide button"
          />
        </label>

        <label>
          Button Link Type
          <select
            value={buttonLinkType}
            onChange={e => setProp((props: any) => (props.buttonLinkType = e.target.value))}
            className="w-full p-1"
          >
            <option value="collection">Collection Link</option>
            <option value="custom">Custom Link</option>
          </select>
        </label>

        {buttonLinkType === 'custom' && (
          <label>
            Custom Link Path
            <textarea
              value={buttonCustomPath || ''}
              onChange={e => setProp((props: any) => (props.buttonCustomPath = e.target.value))}
              className="w-full p-1"
              rows={1}
              placeholder="/custom/path"
            />
          </label>
        )}
      </div>

      {buttonLinkType !== 'custom' && (
        <label>
          Collection Link
          <select
            value={collectionId || ''}
            onChange={e =>
              setProp((props: any) => (props.collectionId = Number(e.target.value) || undefined))
            }
            className="w-full p-1"
          >
            <option value="">All Listings</option>
            {collections.map(collection => (
              <option key={collection.id} value={collection.id}>
                {collection.name}
              </option>
            ))}
          </select>
        </label>
      )}

      <div>
        <div className="font-bold mb-2">Items</div>
        {items.map((item: any, index: number) => (
          <div key={index} className="mb-4 p-2 border border-gray-200">
            <label>
              Image URL
              <textarea
                value={item.imageUrl}
                onChange={e =>
                  setProp((props: any) => {
                    props.items[index].imageUrl = e.target.value;
                  })
                }
                className="w-full p-1"
                rows={1}
              />
            </label>
            <label>
              Blur Image URL
              <textarea
                value={item.imageBlurUrl}
                onChange={e =>
                  setProp((props: any) => {
                    props.items[index].imageBlurUrl = e.target.value;
                  })
                }
                className="w-full p-1"
                rows={1}
              />
            </label>
            <label>
              Title
              <textarea
                value={item.title}
                onChange={e =>
                  setProp((props: any) => {
                    props.items[index].title = e.target.value;
                  })
                }
                className="w-full p-1"
                rows={1}
              />
            </label>
            <div className="py-2">
              <FieldCheckbox
                label="White Text"
                checked={item.whiteText}
                onCheckedChange={checked =>
                  setProp((props: any) => {
                    props.items[index].whiteText = checked;
                  })
                }
              />
            </div>
            <label>
              Link Path
              <textarea
                value={item.link || ''}
                onChange={e => {
                  const value = e.target.value;
                  setProp((props: any) => {
                    // If starts with ?, treat as search params
                    // If starts with /, treat as custom path
                    // Otherwise, clear the value
                    if (value.startsWith('?') || value.startsWith('/')) {
                      props.items[index].link = value;
                    } else if (value === '') {
                      props.items[index].link = '';
                    }
                  });
                }}
                className="w-full p-1"
                rows={1}
                placeholder="Start with ? for search params or / for custom path"
              />
              <div className="text-00 text-gray-500">
                Use ? for search parameters (e.g., ?category=shoes) or / for custom paths (e.g.,
                /custom-page)
              </div>
            </label>
            <div className="py-2">
              <FieldCheckbox
                label="Disable Link"
                checked={item.disableLink || false}
                onCheckedChange={checked =>
                  setProp((props: any) => {
                    props.items[index].disableLink = checked;
                  })
                }
              />
            </div>
            <div className="flex gap-2 mt-2">
              <button
                onClick={() =>
                  setProp((props: any) => {
                    if (index > 0) {
                      const newItems = [...props.items];
                      [newItems[index - 1], newItems[index]] = [
                        newItems[index],
                        newItems[index - 1],
                      ];
                      props.items = newItems;
                    }
                  })
                }
                disabled={index === 0}
                className="bg-blue-500 text-white px-2 py-1 rounded disabled:opacity-50"
              >
                ↑ Move Up
              </button>
              <button
                onClick={() =>
                  setProp((props: any) => {
                    if (index < props.items.length - 1) {
                      const newItems = [...props.items];
                      [newItems[index], newItems[index + 1]] = [
                        newItems[index + 1],
                        newItems[index],
                      ];
                      props.items = newItems;
                    }
                  })
                }
                disabled={index === items.length - 1}
                className="bg-blue-500 text-white px-2 py-1 rounded disabled:opacity-50"
              >
                ↓ Move Down
              </button>
              <button
                onClick={() =>
                  setProp((props: any) => {
                    props.items = props.items.filter((_: any, i: number) => i !== index);
                  })
                }
                className="bg-red-500 text-white px-2 py-1 rounded"
              >
                Remove Item
              </button>
            </div>
          </div>
        ))}
        <button
          onClick={() =>
            setProp((props: any) => {
              props.items = [
                ...props.items,
                {
                  imageUrl: '',
                  imageBlurUrl: '',
                  title: '',
                  link: '',
                },
              ];
            })
          }
          className="bg-green-500 text-white px-2 py-1 rounded"
        >
          Add Item
        </button>
      </div>
    </div>
  );
};
