import { useQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { forwardRef } from 'react';

import { fadeInProps } from '../animations';
import { NewDesktopSection } from './NewDesktopSection';
import { useMediaQueries } from 'hooks/useMediaQueries';
import { cn } from 'util/cn';

const NewInstagramSection = forwardRef<HTMLDivElement, { className?: string }>(
  ({ className }, ref) => {
    const { data } = useQuery({
      queryKey: ['instagramPosts'],
      queryFn: () =>
        fetch('https://thenold-static.s3.eu-central-1.amazonaws.com/instagram.json').then(res =>
          res.json()
        ),
    });

    const isDesktop = useMediaQueries({ viewport: 'medium' });
    return isDesktop ? (
      <NewInstagramSectionDesktop className={className} data={data} ref={ref} />
    ) : (
      <NewInstagramSectionMobile className={className} data={data} ref={ref} />
    );
  }
);

const NewInstagramSectionDesktop = forwardRef<HTMLDivElement, { className?: string; data: any[] }>(
  ({ className, data }, ref) => {
    return (
      <NewDesktopSection
        ref={ref}
        heading={
          <span className="text-3">
            Follow us
            <br />
            on INSTAGRAM
          </span>
        }
      >
        {data
          ?.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
          .map(post => (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a
              href={post.url}
              target="_blank"
              key={post.id}
              className="aspect-square flex-shrink-0 overflow-hidden w-[300px]"
            >
              <img
                src={post.noldImageUrl}
                alt={post.caption}
                className="object-cover object-center w-full h-full"
              />
            </a>
          ))}
      </NewDesktopSection>
    );
  }
);

const NewInstagramSectionMobile = forwardRef<HTMLDivElement, { className?: string; data: any[] }>(
  ({ className, data }, ref) => {
    return (
      <motion.div className={cn('py-5 w-full grid gap-5', className)} {...fadeInProps} ref={ref}>
        <h2 className="font-syne text-3 md:text-5 font-bold uppercase text-center">
          Stalk us
          <br />
          on{' '}
          <a
            className="underline underline-offset-4"
            href="https://www.instagram.com/thenold.circular/"
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>
        </h2>
        <div className="grid grid-cols-3 gap-.5 px-2">
          {data
            ?.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
            .map(post => (
              // eslint-disable-next-line react/jsx-no-target-blank
              <a
                href={post.url}
                target="_blank"
                key={post.id}
                className="aspect-square overflow-hidden"
              >
                <img
                  src={post.noldImageUrl}
                  alt={post.caption}
                  className="object-cover object-center w-full h-full"
                />
              </a>
            ))}
        </div>
      </motion.div>
    );
  }
);

export default NewInstagramSection;
