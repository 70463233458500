import { Editor } from '@craftjs/core';
import loadable from '@loadable/component';

import { AccordionSection } from './components/AccordionSection';
import { Button } from './components/Button';
import { Container } from './components/Container';
import { HeroSection } from './components/HeroSection';
import { Image } from './components/Image';
import { LandingSection } from './components/LandingSection';
import { LeonieHero } from './components/LeonieHero';
import { ListingsSection } from './components/ListingsSection';
import { ShowcaseCarousel } from './components/ShowcaseCarousel';
import { ShowcaseCarouselItem } from './components/ShowcaseCarouselItem';
import { Spacer } from './components/Spacer';
import { Text } from './components/Text';
import TimelineSection from './components/TimelineSection';
import BlackSectionWrapper from './wrappers/BlackSectionWrapper';
import CarouselSectionWrapper from './wrappers/CarouselSectionWrapper';
import FeaturedSectionWrapper from './wrappers/FeaturedSectionWrapper';
import { FollowingSectionWrapper } from './wrappers/FollowingSectionWrapper';
import FooterSectionWrapper from './wrappers/FooterSectionWrapper';
import IconsSectionWrapper from './wrappers/IconsSectionWrapper';
import InstagramSectionWrapper from './wrappers/InstagramSectionWrapper';
import ListingSectionWrapper from './wrappers/ListingSectionWrapper';
import LookingForSectionWrapper from './wrappers/LookingForSectionWrapper';
import { MatrixSectionWrapper } from './wrappers/MatrixSectionWrapper';
import SampleSaleSectionWrapper from './wrappers/SampleSaleSectionWrapper';
import TrendingNowSectionWrapper from './wrappers/TrendingNowSectionWrapper';

const RenderNode = loadable(() => import('./RenderNode'));

export const CustomEditor = ({
  isEditing = false,
  children,
}: {
  isEditing?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Editor
      resolver={{
        Button,
        Container,
        HeroSection,
        LandingSection,
        ListingsSection,
        ShowcaseCarousel,
        ShowcaseCarouselItem,
        Spacer,
        Text,
        Image,
        CarouselSectionWrapper,
        FeaturedSectionWrapper,
        FollowingSectionWrapper,
        IconsSectionWrapper,
        InstagramSectionWrapper,
        LookingForSectionWrapper,
        MatrixSectionWrapper,
        BlackSectionWrapper,
        SampleSaleSectionWrapper,
        TrendingNowSectionWrapper,
        ListingSectionWrapper,
        AccordionSection,
        LeonieHero,
        TimelineSection,
        FooterSectionWrapper,
      }}
      onRender={isEditing ? ({ render }) => <RenderNode render={render} /> : ({ render }) => render}
      enabled={isEditing}
    >
      {children}
    </Editor>
  );
};
