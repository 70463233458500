import { useNode, UserComponent } from '@craftjs/core';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useState } from 'react';

import { fadeInProps } from 'containers/CustomLandingPage/animations';

type LeonieHeroProps = {
  children?: React.ReactNode;
};

export function useProgressiveImage({ blurUrl, fullUrl }: { blurUrl: string; fullUrl: string }) {
  const [imageSrc, setImageSrc] = useState(blurUrl);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!fullUrl) return;

    const img = new Image();
    img.src = fullUrl;
    img.decode().then(() => {
      setImageSrc(fullUrl);
      setIsLoaded(true);
    });
  }, [fullUrl]);

  return { imageSrc, blurSrc: blurUrl, isLoaded };
}

export const LeonieHero: UserComponent<LeonieHeroProps> = ({ children }) => {
  const {
    connectors: { connect, drag },
  } = useNode();

  const { imageSrc: imageSrc1, blurSrc: blurSrc1 } = useProgressiveImage({
    blurUrl: 'https://cdn.thenold.com/images/2024-12-10_11-31-34__Leonie_blur.webp',
    fullUrl: 'https://cdn.thenold.com/images/2024-12-10_11-31-34__Leonie_compressed.jpg',
  });
  const { imageSrc: imageSrc2 } = useProgressiveImage({
    blurUrl: 'https://cdn.thenold.com/images/2024-12-10_12-10-07__Leonie_nobg_blur.webp',
    fullUrl: 'https://cdn.thenold.com/images/2024-12-10_12-10-07__Leonie_nobg_compressed.webp',
  });

  return (
    <motion.div
      {...fadeInProps}
      ref={ref => ref && connect(drag(ref))}
      className="relative isolate overflow-hidden grid content-end w-full h-[680px] max-h-[min(680px,70vh)] bg-cover bg-center bg-repeat-x sm:bg-contain"
      style={{
        backgroundImage: ` url(${imageSrc1}), url(${blurSrc1})`,
      }}
    >
      <div className="absolute inset-4 grid place-items-center -translate-y-[15%]">
        <svg
          height="70%"
          viewBox="0 0 200 200"
          className="animate-spin origin-center [animation-duration:20s] [transform-box:fill-box]"
        >
          <defs>
            <path
              id="circlePath"
              d="M 100, 100 m -75, 0 a 75,75 0 1,1 150,0 a 75,75 0 1,1 -150,0"
            />
          </defs>
          <text
            fill="white"
            font-family="syne"
            font-weight="bold"
            font-size="13"
            text-anchor="middle"
            letter-spacing="0.05em"
          >
            <textPath href="#circlePath" startOffset="25%">
              LEONIE HANNE x THE NOLD
            </textPath>
            <textPath href="#circlePath" startOffset="75%">
              LEONIE HANNE x THE NOLD
            </textPath>
          </text>
        </svg>
      </div>
      <div
        className="absolute inset-0 z-10 bg-cover bg-center bg-repeat-x sm:bg-contain"
        style={{
          backgroundImage: `url(${imageSrc2})`,
        }}
      ></div>

      <div className="relative z-10 grid gap-2 w-full max-w-[var(--n-content-max-width)] mx-auto px-2 py-10 md:px-10 ">
        <div className="absolute z-[-1] inset-0 -inset-x-[100vw] w-[1000vw] bg-gradient-to-t from-black/20 via-black/30 to-transparent" />
        {children}
      </div>
    </motion.div>
  );
};

LeonieHero.craft = {
  displayName: 'LeonieHero',
  related: {},
};
