import { useNode } from '@craftjs/core';

import { useGetConfiguration } from 'hooks/api/useGetConfiguration';

export const ListingSectionSettings = () => {
  const {
    actions: { setProp },
    collectionId,
    title,
    subtitle,
    className,
    buttonText,
  } = useNode(node => ({
    collectionId: node.data.props.collectionId,
    title: node.data.props.title,
    subtitle: node.data.props.subtitle,
    className: node.data.props.className,
    buttonText: node.data.props.buttonText,
  }));

  const { data: configData } = useGetConfiguration();
  const collections = configData?.collections || [];

  return (
    <div className="flex flex-col gap-2">
      <div>Listings Section Settings</div>

      <label>
        Collection
        <select
          value={collectionId || ''}
          onChange={e =>
            setProp((props: any) => (props.collectionId = Number(e.target.value) || undefined))
          }
          className="w-full p-1"
        >
          <option value="">All Listings</option>
          {collections.map(collection => (
            <option key={collection.id} value={collection.id}>
              {collection.name}
            </option>
          ))}
        </select>
      </label>

      <label>
        Title
        <input
          type="text"
          value={title}
          onChange={e => setProp((props: any) => (props.title = e.target.value))}
          className="w-full p-1"
        />
      </label>

      <label>
        Subtitle
        <input
          type="text"
          value={subtitle}
          onChange={e => setProp((props: any) => (props.subtitle = e.target.value))}
          className="w-full p-1"
        />
      </label>

      <label>
        Class Name
        <input
          type="text"
          value={className}
          onChange={e => setProp((props: any) => (props.className = e.target.value))}
          className="w-full p-1"
        />
      </label>

      <label>
        Button Text
        <input
          type="text"
          value={buttonText}
          onChange={e => setProp((props: any) => (props.buttonText = e.target.value))}
          className="w-full p-1"
        />
      </label>

      <div>
        <div className="font-bold mb-2">Listings</div>
        <p className="text-sm text-gray-500">
          Listings are populated automatically based on the selected collection.
        </p>
      </div>
    </div>
  );
};
