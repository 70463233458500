import { forwardRef, useRef } from 'react';

import emptyImagePlaceholder from '../../../../assets/empty-image-placeholder.png';
import { useGetUserSocials } from '../../../../hooks/api/users/useGetUserSocials';
import css from './FollowingSection.module.css';
import { CarouselNavigationButtons, NamedLink } from 'components';
import { RouteName } from 'routing/routeConfiguration';

const FollowingSection = forwardRef<HTMLDivElement>((_, ref) => {
  const { data: userSocials, isLoading } = useGetUserSocials();
  const following = userSocials?.following || [];
  const defaultFollowing = userSocials?.defaultFollowing || [];
  const results = isLoading
    ? new Array(8).fill({
        id: '',
        imageUrl: emptyImagePlaceholder,
        handle: '...',
      })
    : [
        ...following,
        ...defaultFollowing.sort((a, b) =>
          a.id === '675ab53b-f9c6-4645-b58e-ec754d697e9f' ? -1 : 1
        ),
      ];

  const socialCirclesData = results.map((user, i) => ({
    id: user.id || `placeholder-${i}`,
    imageUrl: user.imageUrl,
    label: user.handle,
    initials: user.fullName
      ? `${user.fullName
          .split(' ')
          .filter(n => n)
          .map(n => n[0].toUpperCase())
          .join('')}`
      : user.handle[0].toUpperCase(),
    linkProps: user.id ? { name: 'ProfilePage' as RouteName, params: { id: user.id } } : undefined,
  }));

  return <SocialCircles items={socialCirclesData} ref={ref} />;
});

export default FollowingSection;

export interface SocialCirclesProps {
  items: Array<{
    id: string;
    imageUrl: string;
    label: string;
    initials: string;
    linkProps?: {
      name: RouteName;
      params: { [key: string]: string };
    };
  }>;
}

export const SocialCircles = forwardRef<HTMLDivElement, SocialCirclesProps>(({ items }, ref) => {
  const carouselRef = useRef<HTMLDivElement>(null);

  return (
    <div className={css.root} ref={ref}>
      <div className={css.items} ref={carouselRef}>
        {items.map((item, i) => {
          const Component = item.linkProps?.name ? NamedLink : 'div';
          const linkProps: any = item.linkProps || {};

          return (
            <Component key={item.id || i} className={css.item} {...linkProps}>
              <picture className="relative">
                <source srcSet={item.imageUrl} />
                {item.imageUrl && <img src={item.imageUrl} alt={item.label} />}
                {!item.imageUrl && (
                  <div className="absolute rounded-full text-000 inset-0 flex items-center justify-center bg-border tracking-widest text-type-dark font-semibold">
                    {item.initials}
                  </div>
                )}
              </picture>
              <p>{item.label} </p>
            </Component>
          );
        })}
      </div>
      <CarouselNavigationButtons carouselRef={carouselRef} />
    </div>
  );
});
