import { motion } from 'framer-motion';
import { forwardRef } from 'react';

import { fadeInProps } from '../animations';
import { NewDesktopSection } from './NewDesktopSection';
import { ListingCard, NamedLink, PrimaryButton } from 'components';
import { useMediaQueries } from 'hooks/useMediaQueries';
import { cn } from 'util/cn';

type P = {
  className?: string;
  listings: any[];
  title?: string;
  subtitle?: string;
  filterLink?: string;
  buttonText?: string;
};

const NewListingsSection = forwardRef<HTMLDivElement, P>(
  (
    {
      className,
      listings = [],
      title = 'Recently added',
      subtitle = 'Pre-loved',
      filterLink,
      buttonText,
    },
    ref
  ) => {
    const isDesktop = useMediaQueries({ viewport: 'small' });
    return isDesktop ? (
      <NewListingsSectionDesktop
        className={className}
        listings={listings}
        title={title}
        subtitle={subtitle}
        filterLink={filterLink}
        buttonText={buttonText}
        ref={ref}
      />
    ) : (
      <NewListingsSectionMobile
        className={className}
        listings={listings}
        title={title}
        subtitle={subtitle}
        filterLink={filterLink}
        buttonText={buttonText}
        ref={ref}
      />
    );
  }
);

const NewListingsSectionDesktop = forwardRef<
  HTMLDivElement,
  {
    className?: string;
    listings: any[];
    title: string;
    subtitle: string;
    filterLink?: string;
    buttonText?: string;
  }
>(({ className, listings = [], title, subtitle, filterLink, buttonText = 'Shop all' }, ref) => {
  return (
    <NewDesktopSection
      heading={
        <>
          <h3>
            <span className="bg-custom-green text-1">{subtitle}</span>
          </h3>
          <h2>{title}</h2>
          {buttonText && (
            <NamedLink name="SearchPage" to={{ search: filterLink }}>
              <PrimaryButton>{buttonText}</PrimaryButton>
            </NamedLink>
          )}
        </>
      }
      ref={ref}
    >
      {listings.map(l => (
        <div className="flex-shrink-0 w-[250px] px-2 pt-4" key={l.id}>
          <ListingCard rootClassName="flex-shrink-0" listing={l} />
        </div>
      ))}
    </NewDesktopSection>
  );
});

const NewListingsSectionMobile = forwardRef<
  HTMLDivElement,
  {
    className?: string;
    listings: any[];
    title: string;
    subtitle: string;
    filterLink?: string;
    buttonText?: string;
  }
>(({ className, listings = [], title, subtitle, filterLink, buttonText = 'Shop all' }, ref) => {
  return (
    <motion.div
      className={cn('pt-5 pb-10 w-full grid gap-5', className)}
      {...fadeInProps}
      ref={ref}
    >
      <div className="flex flex-col gap-1 items-center">
        <h3 className="font-syne text-0 font-bold uppercase bg-custom-green">{subtitle}</h3>
        <h2 className="font-syne text-3 md:text-5 font-bold uppercase">{title}</h2>
        {buttonText && (
          <NamedLink
            name="SearchPage"
            params={filterLink ? { pub_filterLink: filterLink } : undefined}
            className="flex justify-center"
          >
            <PrimaryButton>{buttonText}</PrimaryButton>
          </NamedLink>
        )}
      </div>
      <div className="flex gap-2 overflow-x-auto px-2">
        {listings.map(l => (
          <ListingCard rootClassName="flex-shrink-0 w-[40vw]" key={l.id} listing={l} />
        ))}
      </div>
    </motion.div>
  );
});

export default NewListingsSection;
